<template>
    <div>
        <v-snackbar v-model="snackbar" color="success" top :timeout="5000">
          <v-row>
            Your email was sent successfully
            <v-spacer></v-spacer>
            <v-btn
              dark
              icon
              @click="snackbar = false"
            >
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-snackbar>
        <transition-group name="list" tag="div">
        <v-card v-for="(flight) in flights" :key="flight.id" class="mb-10 rounded-lg" outlined>
            <div v-if="flight.withAlternateDate" style="background-color: #0ec50e; width: 100%; color: white;">
                <v-avatar left>
                    <v-icon color="white">mdi-airplane-clock</v-icon>
                </v-avatar>
                Alternate Date
            </div>
            <div v-if="flight.mailOnlyFare" style="background-color: #2f80c2; width: 100%; color: white;">
                <v-avatar left>
                    <v-icon color="white">mdi-account-check</v-icon>
                </v-avatar>
                Fare33 Member Fares
            </div>
            <v-alert
            border="left"
            icon="mdi-alert"
            colored-border
            type="warning"
            elevation="0"
            color="warning"
            v-if="flight.remainingSeats < 9"
            dense
            class="ma-2"
            >
                {{flight.remainingSeats}} Seats left, hurry up and book your seat(s) now.
            </v-alert>
            <v-row justify="space-between" align="center">
                <v-col cols="12" :md="$route.name === 'book' ? 12 : 8" class="pt-5">
                    <v-row class="mb-3 ml-3" :style="i < flight.flightSegments.length - 1 ? 'border-bottom: 0.5px solid #e9e9e9' : ''" no-gutters align="center" v-for="(flightSegment, i) in flight.flightSegments" :key="i">
                        <v-col cols="12" md="5" v-if="!flight.mailOnlyFare" class="d-flex align-start">
                            <img :src="flightSegment.Segments[0].MarketingAirlineLogoSM" height="50" class="px-2"/>
                            <div>
                                <v-tooltip bottom color="blueDark darken-1">
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on" class="airline font-weight-medium greyText--text">{{flightSegment.Segments[0].MarketingAirlineName}}</span>
                                    </template>
                                    <span>{{flightSegment.Segments[0].MarketingAirlineName}}</span>
                                </v-tooltip>
                                <p class="mb-0 caption grey--text">Operated by {{flightSegment.Segments[0].OperatingAirlineName}}</p>
                                <v-subheader class="primary--text caption d-inline-flex px-0">{{ getThisDate(flightSegment.Segments[0].DepartureDate) }}</v-subheader>
                                -
                                <v-subheader class="primary--text caption d-inline-flex px-0">{{ getThisDate(flightSegment.Segments[flightSegment.Segments.length - 1].ArrivalDate) }}</v-subheader>
                            </div>
                            <!-- <span class="caption">Operated by {{flightSegment.Segments[0].OperatingAirlineName}}</span> -->
                        </v-col>
                        <v-col cols="12" md="7" class="pa-5" v-else>
                            <v-row>
                                <v-icon color="secondary" class="mx-5">mdi-email-check</v-icon>
                                <v-icon color="grey lighten-1">mdi-shield-airplane</v-icon>
                            </v-row>
                            <p class="body-2 mt-5 mb-0">email-only-fare</p>
                        </v-col>
                        <v-col cols="12" md="6" class="px-5">
                                <div class="d-flex justify-space-between align-center">
                                    <div class="airline">
                                        <p class="airline font-weight-bold greyText--text text-right">
                                            <v-icon x-large color="airline greyText--text">mdi-airplane-takeoff</v-icon>
                                            {{ flightSegment.Segments[0].DepartureTime }}
                                            <br/>
                                            {{ flightSegment.Segments[0].OriginLocationCode }}
                                        </p>
                                    </div>
                                    <v-tooltip bottom nudge-top="30" color="blueDark darken-1">
                                        <template v-slot:activator="{ on }">
                                            <p v-on="on" class="body-2 primary--text mb-0">{{flightSegment.stops}}Stops</p>
                                        </template>
                                        <span style="transform: translateX(-50%)">
                                            <p v-for="n in flightSegment.stops" :key="n">{{flightSegment.Segments[n-1].DestinationLocation.split(',')[0]}} - <span>{{flightSegment.Segments[n-1].Duration}}h</span></p>
                                        </span>
                                    </v-tooltip>
                                    <div>
                                        <p class="airline font-weight-bold greyText--text text-right">
                                            <v-icon x-large color="airline greyText--text">mdi-airplane-landing</v-icon>
                                            {{ flightSegment.Segments[flightSegment.Segments.length - 1].ArrivalTime }}
                                            <br/>
                                            {{ flightSegment.Segments[flightSegment.Segments.length - 1].DestinationLocationCode }}
                                        </p>
                                    </div>
                                </div>
                            <!-- </div> -->
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="4" class="text-center py-5" v-if="$route.name !== 'book'">
                    <div class="d-flex justify-space-around">
                        <v-divider class="d-none d-md-block" vertical></v-divider>
                        <div>
                            <p v-if="!flight.pricingInfo.newFare > 0" class="font-weight-black primary--text text-h6 text-center">${{flight.pricingInfo.TotalFare.toFixed(2)}}</p>
                            <div v-else>
                                <p class="body-2 text-center">${{flight.pricingInfo.TotalFare.toFixed(2)}}</p>
                                <p class="body-1 font-weight-black text-center">- ${{flight.pricingInfo.discount_amount}}</p>
                                <v-divider></v-divider>
                                <p class="font-weight-black primary--text text-h6 text-center">${{flight.pricingInfo.newFare.toFixed(2)}}</p>
                            </div>
                            <p class="caption text-center grey--text lighten-1 my-0">{{flight.pricingInfo.totalFareNote}}</p>
                            <br>
                            <p v-if="flight.pricingInfo.premiumNote" class="caption success--text text-center lighten-1 my-0">{{flight.pricingInfo.premiumNote}}</p>
                            <v-btn v-if="!flight.mailOnlyFare" color="primary" :name="`${flight.netFlight ? 'Net Flight' : ''}`" elevation="0" x-large @click="book(flight.id, flight.session_time)" class="d-block mx-auto white--text rounded-lg px-10">Select</v-btn>
                            <v-btn
                            text
                            small
                            color="blue"
                            @click="expand(flight.id)"
                            v-if="!flight.mailOnlyFare"
                            >
                                More Details
                                <v-icon right>{{ show && currentFlight === flight.id ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <!-- <v-card-actions class="actions">
                <v-btn
                text
                small
                color="blue"
                @click="expand(flight.id)"
                v-if="!flight.mailOnlyFare"
                >
                    More Details
                    <v-icon right>{{ show && currentFlight === flight.id ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
                <v-spacer v-if="flight.mailOnlyFare || flight.flightsWithSamePrice.length > 0"></v-spacer>
                <v-btn v-if="flight.flightsWithSamePrice.length > 0 && !flight.mailOnlyFare" color="blueDark" tile @click="openSamePriceFlightsDialog(flight.flightsWithSamePrice, flight.id)" class="my-0 white--text">More flights at this price</v-btn>
                <v-btn v-if="flight.mailOnlyFare" color="blueDark" tile @click="showBookingDialog = true; flightId = flight.id" class="my-0 white--text">Send Email to book</v-btn>
            </v-card-actions> -->
            <v-expand-transition>
                <div v-show="show && currentFlight === flight.id">
                    <v-divider></v-divider>
                    <v-container>
                        <div v-for="(flightSegment, i) in flight.flightSegments" :key="i">
                            <v-row v-if="i === 0" justify="space-between" class="pa-3">
                                <h3 class="body-1 font-weight-bold primary--text">
                                    Depart <span class="caption grey--text">{{getThisDate(flightSegment.Segments[0].DepartureDate)}} / {{flightSegment.TotalDuration}} hours</span>
                                </h3>
                                <v-btn color="secondary" v-if="flightSegment.baggage_rules && $route.name !== 'book'" tile small @click="baggageDialog = true; baggageRules = flightSegment.baggage_rules"><v-icon left>mdi-bag-checked</v-icon> Baggage Rules</v-btn>
                            </v-row>
                            <v-row v-else justify="space-between" class="pa-3">
                                <h3 class="body-1 font-weight-bold primary--text">
                                    Return <span class="caption grey--text">{{getThisDate(flightSegment.Segments[0].DepartureDate)}} / {{flightSegment.TotalDuration}} hours</span>
                                </h3>
                                <v-btn color="secondary" v-if="flightSegment.baggage_rules && $route.name !== 'book'" text small @click="baggageDialog = true; baggageRules = flightSegment.baggage_rules"><v-icon left>mdi-bag-checked</v-icon> Baggage Rules</v-btn>
                            </v-row>
                            <v-row v-for="(segment, n) in flightSegment.Segments" :key="n">
                                <v-col cols="10" sm="3">
                                    <v-row>
                                        <v-col cols="4">
                                            <img :src="segment.MarketingAirlineLogoSM" height="30"/>
                                        </v-col>
                                        <v-col cols="8">
                                            <v-tooltip top color="blueDark darken-1">
                                                <template v-slot:activator="{ on }">
                                                    <p v-on="on" class="body-2">{{segment.MarketingAirlineName.substr(0, 6)}}</p>
                                                </template>
                                                <span>{{segment.MarketingAirlineName}}</span>
                                            </v-tooltip>
                                            <p class="caption">Operated by {{flightSegment.Segments[0].OperatingAirlineName}}</p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="2" class="d-block d-sm-none">
                                    <p class="caption">
                                        <v-icon color="secondary">mdi-timer-outline</v-icon>
                                        {{segment.Duration}} hours
                                    </p>
                                </v-col>
                                <v-col cols="3" sm="2">
                                    <p class="body-2">{{getThisDate(segment.DepartureDate)}}</p>
                                </v-col>
                                <v-col cols="3" sm="2">
                                    <p class="body-2">{{segment.DepartureTime}}</p>
                                    <p class="body-2">{{segment.OriginLocation.substr(0, segment.OriginLocation.indexOf(','))}},{{segment.OriginLocationCode}}</p>
                                </v-col>
                                <v-col cols="3" sm="2">
                                    <p class="body-2">{{segment.ArrivalTime}}</p>
                                    <p class="body-2">{{segment.DestinationLocation.substr(0, segment.DestinationLocation.indexOf(','))}},{{segment.DestinationLocationCode}}</p>
                                </v-col>
                                <v-col cols="3" sm="2">
                                    <p class="body-2">{{getThisDate(segment.ArrivalDate)}}</p>
                                </v-col>
                                <v-col cols="12" sm="1" class="d-none d-sm-block">
                                    <p class="caption">
                                        <v-icon color="secondary">mdi-timer-outline</v-icon>
                                        {{segment.Duration}} hours
                                    </p>
                                </v-col>
                                <v-col cols="12" class="py-0 pb-5 text-right">
                                    <v-row no-gutters justify="space-around">
                                        <small><v-icon small>mdi-airplane</v-icon> {{segment.aircraft}} / Flight {{segment.FlightNumber}}</small>
                                        <small class="secondary--text">Booking Class ({{segment.BookingClass}})</small>
                                        <small v-if="segment.classCabin" class="secondary--text text-capitalize">Cabin Class ({{segment.classCabin}})</small>
                                        <small v-if="segment.departureTerminal" class="secondary--text text-capitalize">Departure Terminal ({{segment.departureTerminal}})</small>
                                        <small v-if="segment.arrivalTerminal" class="secondary--text text-capitalize">Arrival Terminal ({{segment.arrivalTerminal}})</small>
                                    </v-row>
                                    <v-divider v-if="n + 1 < flightSegment.Segments.length" class="mt-5"></v-divider>
                                    <div class="layover caption" v-if="n + 1 < flightSegment.Segments.length">Layover {{flightSegment.stopsDuration[n].text}} h</div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-container>
                </div>
            </v-expand-transition>
        </v-card>
        </transition-group>
        <v-dialog max-width="700" v-model="baggageDialog">
            <v-card>
                <v-card-title
                class="headline blueDark white--text font-weight-regular"
                primary-title
                >
                <span>{{baggageRules.airlineName}}, <sub class="caption">{{baggageRules.airlineCode}} </sub> Baggage Rules</span>
                </v-card-title>
                <v-card-text v-if="baggageRules" class="text-right mt-5">
                    <a class="my-3" v-if="baggageRules.baggage_url && baggageRules.baggage_url !== ''" target="_blank" :href="baggageRules.baggage_url">Show All Baggage Rules</a>
                    <div v-if="baggageRules.freePieces">
                        <p class="headline text-center secondary--text">Free Pieces</p>
                        <v-simple-table>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Number Of Pieces
                                    </th>
                                    <th class="text-left">
                                        Weight
                                    </th>
                                    <th class="text-left">
                                        Size
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left">{{ baggageRules.freePieces.numberOfPieces }}</td>
                                    <td class="text-left">{{ baggageRules.freePieces.weight }}</td>
                                    <td class="text-left">{{ baggageRules.freePieces.size }}</td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </div>
                    <div v-if="baggageRules.additionalCost">
                        <v-divider class="mt-2 mb-5"></v-divider>
                        <p class="headline text-center secondary--text">Additional Cost</p>
                        <v-simple-table>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Fees
                                    </th>
                                    <th class="text-left">
                                        Weight
                                    </th>
                                    <th class="text-left">
                                        Size
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left">$ {{ baggageRules.additionalCost.fees }}</td>
                                    <td class="text-left">{{ baggageRules.additionalCost.weight }}</td>
                                    <td class="text-left">{{ baggageRules.additionalCost.size }}</td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showBookingDialog" :width="$vuetify.breakpoint.mdAndUp ? '90%' : '80%'">
            <!-- <mailForm @success="snackbar = true" @close="showBookingDialog = false" :flightId="flightId"></mailForm> -->
        </v-dialog>
        <v-dialog v-model="flightsWithSamePriceDialog" width="80%">
            <v-sheet color="white" class="pa-2">
            <div class="headline blueDark--text d-flex justify-space-between">
                <p><v-icon color="blueDark">mdi-airplane</v-icon> More Flights At Same Price</p>
                <v-btn icon color="blueDark" @click="flightsWithSamePriceDialog = false"><v-icon>mdi-close</v-icon></v-btn>
            </div>
            <v-card v-for="(flight) in samePriceFlights.flights" :key="flight.id" class="my-5" :outlined="$route.name === 'book'">
                <div v-if="flight.withAlternateDate" style="background-color: #0ec50e; width: 100%; color: white;">
                    <v-avatar left>
                        <v-icon color="yellow">mdi-star</v-icon>
                    </v-avatar>
                    Alternate Date
                </div>
                <v-container fluid>
                    <div price="1" v-if="flight.pricingInfo.fare_type === 'Net'"></div>
                    <v-row align="center">
                        <v-col cols="12" :md="$route.name === 'book' ? 12 : 10" class="py-3">
                            <v-alert
                            border="left"
                            icon="mdi-alert"
                            colored-border
                            type="warning"
                            elevation="0"
                            color="warning"
                            v-if="flight.remainingSeats < 9"
                            dense
                            >
                            {{flight.remainingSeats}} Seats left, hurry up and book your seat(s) now.
                            </v-alert>
                            <v-row justify="center" align="center" v-for="(flightSegment, i) in flight.flightSegments" :key="i">
                                <v-col cols="12" sm="2" v-if="!flight.mailOnlyFare">
                                    <img :src="flightSegment.Segments[0].MarketingAirlineLogoSM" width="50"/>
                                    <v-tooltip bottom color="blueDark darken-1">
                                        <template v-slot:activator="{ on }">
                                            <p v-on="on" class="airline font-weight-medium">{{flightSegment.Segments[0].MarketingAirlineName.substr(0, 15)}}...</p>
                                        </template>
                                        <span>{{flightSegment.Segments[0].MarketingAirlineName}}</span>
                                    </v-tooltip>
                                    <span class="caption">Operated by {{flightSegment.Segments[0].OperatingAirlineName}}</span>
                                </v-col>
                                <v-col cols="12" sm="2" class="pt-5" v-else>
                                    <v-row>
                                        <v-icon color="secondary" class="mx-5">mdi-email-check</v-icon>
                                        <v-icon color="grey lighten-1">mdi-shield-airplane</v-icon>
                                    </v-row>
                                    <p class="body-2 mt-5 mb-0">email-only-fare</p>
                                </v-col>
                                <v-col>
                                    <v-subheader>{{ getThisDate(flightSegment.Segments[0].DepartureDate) }}</v-subheader>
                                </v-col>
                                <v-col class="d-flex d-sm-none">
                                    <v-subheader>{{ getThisDate(flightSegment.Segments[flightSegment.Segments.length - 1].ArrivalDate) }}</v-subheader>
                                </v-col>
                                <v-tooltip bottom nudge-top="30" color="blueDark darken-1">
                                    <template v-slot:activator="{ on }">
                                        <v-col cols="12" sm="6" class="text-center">
                                            <div style="display: flex; justify-content: center; position: relative;">
                                                <div>
                                                    <v-subheader class="caption pr-2">
                                                        {{ flightSegment.Segments[0].DepartureTime }},
                                                        <strong class="strong">{{ flightSegment.Segments[0].OriginLocationCode }}</strong>
                                                    </v-subheader>
                                                </div>
                                                <v-icon small color="primary">mdi-airplane-takeoff</v-icon>
                                                <v-icon small color="primary">mdi-minus</v-icon>
                                                <div v-if="flightSegment.stops > 0" style="display: flex;">
                                                    <div v-for="n in flightSegment.stops" :key="n">
                                                        <v-icon small color="primary" style="transform: translateY(10px);" v-if="n >= 2">mdi-minus</v-icon>
                                                        <v-icon x-small color="secondary" style="transform: translateY(10px);">mdi-checkbox-blank-circle</v-icon>
                                                    </div>
                                                </div>
                                                <v-icon small color="primary">mdi-minus</v-icon>
                                                <v-icon small color="primary">mdi-airplane-landing</v-icon>
                                                <div>
                                                    <v-subheader class="caption">
                                                        {{ flightSegment.Segments[flightSegment.Segments.length - 1].ArrivalTime }},
                                                        <strong>{{ flightSegment.Segments[flightSegment.Segments.length - 1].DestinationLocationCode }}</strong>
                                                    </v-subheader>
                                                </div>
                                                <p v-on="on" v-if="flightSegment.stops > 0" class="body-2 font-weight-thin" style="position: absolute; left: 50%; top: 100%; transform: translate(-50%, -50%); width: 50px;">{{flightSegment.stops}}Stops</p>
                                            </div>
                                        </v-col>
                                        </template>
                                    <span style="transform: translateX(-50%)">
                                        <p v-for="n in flightSegment.stops" :key="n">{{flightSegment.Segments[n-1].DestinationLocation.split(',')[0]}} - <span>{{flightSegment.Segments[n-1].Duration}}h</span></p>
                                    </span>
                                </v-tooltip>
                                <v-col class="d-none d-sm-flex">
                                    <v-subheader>{{ getThisDate(flightSegment.Segments[flightSegment.Segments.length - 1].ArrivalDate) }}</v-subheader>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="2" class="select price" v-if="$route.name !== 'book'">
                            <div class="text-center">
                                <p v-if="!flight.pricingInfo.newFare > 0" class="font-weight-black text-h6">${{flight.pricingInfo.TotalFare.toFixed(2)}}</p>
                                <div v-else>
                                    <p class="body-2">${{flight.pricingInfo.TotalFare.toFixed(2)}}</p>
                                    <p class="body-1 font-weight-black">- ${{flight.pricingInfo.discount_amount}}</p>
                                    <v-divider></v-divider>
                                    <p class="font-weight-black text-h6">${{flight.pricingInfo.newFare.toFixed(2)}}</p>
                                </div>
                                <p class="grey--text lighten-1 my-0">{{flight.pricingInfo.totalFareNote}}</p>
                                <br>
                                <p v-if="flight.pricingInfo.premiumNote" class="success--text lighten-1 my-0">{{flight.pricingInfo.premiumNote}}</p>
                                <v-btn v-if="!flight.mailOnlyFare" block color="blueDark" :name="`${flight.netFlight ? 'Net Flight' : ''}`" tile @click="book(flight.id, samePriceFlights.relatedFlightId)" class="my-0 white--text">View details</v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-actions class="actions">
                    <v-btn
                    text
                    small
                    color="blue"
                    @click="expand(flight.id)"
                    v-if="!flight.mailOnlyFare"
                    >
                        More Details
                        <v-icon right>{{ show && currentFlight === flight.id ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </v-btn>
                    <v-btn v-if="flight.mailOnlyFare" color="blueDark" tile @click="$router.push({ name: 'login' })" class="my-0 white--text">Login</v-btn>
                    <v-spacer v-if="flight.mailOnlyFare"></v-spacer>
                    <v-btn v-if="flight.mailOnlyFare" color="blueDark" tile @click="showBookingDialog = true; flightId = flight.id" class="my-0 white--text">not a member? Email to book</v-btn>
                </v-card-actions>
                <v-expand-transition>
                    <div v-show="show && currentFlight === flight.id">
                        <v-divider></v-divider>
                        <v-container>
                            <div v-for="(flightSegment, i) in flight.flightSegments" :key="i">
                                <v-row v-if="i === 0" justify="space-between" class="pa-3">
                                    <h3 class="body-1 font-weight-bold primary--text">
                                        Depart <span class="caption grey--text">{{getThisDate(flightSegment.Segments[0].DepartureDate)}} / {{flightSegment.TotalDuration}} hours</span>
                                    </h3>
                                    <v-btn color="secondary" v-if="flightSegment.baggage_rules && $route.name !== 'book'" tile small @click="baggageDialog = true; baggageRules = flightSegment.baggage_rules"><v-icon left>mdi-bag-checked</v-icon> Baggage Rules</v-btn>
                                </v-row>
                                <v-row v-else justify="space-between" class="pa-3">
                                    <h3 class="body-1 font-weight-bold primary--text">
                                        Return <span class="caption grey--text">{{getThisDate(flightSegment.Segments[0].DepartureDate)}} / {{flightSegment.TotalDuration}} hours</span>
                                    </h3>
                                    <v-btn color="secondary" v-if="flightSegment.baggage_rules && $route.name !== 'book'" text small @click="baggageDialog = true; baggageRules = flightSegment.baggage_rules"><v-icon left>mdi-bag-checked</v-icon> Baggage Rules</v-btn>
                                </v-row>
                                <v-row v-for="(segment, n) in flightSegment.Segments" :key="n">
                                    <v-col cols="10" sm="3">
                                        <v-row>
                                            <v-col cols="4">
                                                <img :src="segment.MarketingAirlineLogoSM" height="30"/>
                                            </v-col>
                                            <v-col cols="8">
                                                <v-tooltip top color="blueDark darken-1">
                                                    <template v-slot:activator="{ on }">
                                                        <p v-on="on" class="body-2">{{segment.MarketingAirlineName.substr(0, 6)}}</p>
                                                    </template>
                                                    <span>{{segment.MarketingAirlineName}}</span>
                                                </v-tooltip>
                                                <p class="caption">Operated by {{flightSegment.Segments[0].OperatingAirlineName}}</p>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="2" class="d-block d-sm-none">
                                        <p class="caption">
                                            <v-icon color="secondary">mdi-timer-outline</v-icon>
                                            {{segment.Duration}} hours
                                        </p>
                                    </v-col>
                                    <v-col cols="3" sm="2">
                                        <p class="body-2">{{getThisDate(segment.DepartureDate)}}</p>
                                    </v-col>
                                    <v-col cols="3" sm="2">
                                        <p class="body-2">{{segment.DepartureTime}}</p>
                                        <p class="body-2">{{segment.OriginLocation.substr(0, segment.OriginLocation.indexOf(','))}},{{segment.OriginLocationCode}}</p>
                                    </v-col>
                                    <v-col cols="3" sm="2">
                                        <p class="body-2">{{segment.ArrivalTime}}</p>
                                        <p class="body-2">{{segment.DestinationLocation.substr(0, segment.DestinationLocation.indexOf(','))}},{{segment.DestinationLocationCode}}</p>
                                    </v-col>
                                    <v-col cols="3" sm="2">
                                        <p class="body-2">{{getThisDate(segment.ArrivalDate)}}</p>
                                    </v-col>
                                    <v-col cols="12" sm="1" class="d-none d-sm-block">
                                        <p class="caption">
                                            <v-icon color="secondary">mdi-timer-outline</v-icon>
                                            {{segment.Duration}} hours
                                        </p>
                                    </v-col>
                                    <v-col cols="12" class="py-0 pb-5 text-right">
                                        <v-row no-gutters justify="space-around">
                                            <small><v-icon small>mdi-airplane</v-icon> {{segment.aircraft}} / Flight {{segment.FlightNumber}}</small>
                                            <small class="secondary--text">Booking Class ({{segment.BookingClass}})</small>
                                            <small v-if="segment.classCabin" class="secondary--text text-capitalize">Cabin Class ({{segment.classCabin}})</small>
                                            <small v-if="segment.departureTerminal" class="secondary--text text-capitalize">Departure Terminal ({{segment.departureTerminal}})</small>
                                            <small v-if="segment.arrivalTerminal" class="secondary--text text-capitalize">Arrival Terminal ({{segment.arrivalTerminal}})</small>
                                        </v-row>
                                        <v-divider v-if="n + 1 < flightSegment.Segments.length" class="mt-5"></v-divider>
                                        <div class="layover caption" v-if="n + 1 < flightSegment.Segments.length">Layover {{flightSegment.stopsDuration[n].text}} h</div>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-container>
                    </div>
                </v-expand-transition>
            </v-card>
            </v-sheet>
        </v-dialog>
    </div>
</template>

<script>
// import mailForm from './sendMailForm.vue'

export default {
  props: ['flights', 'type'],
  components: {
    // mailForm
  },
  data () {
    return {
      show: false,
      currentFlight: null,
      baggageDialog: false,
      baggageRules: [],
      showBookingDialog: false,
      flightId: null,
      snackbar: false,
      samePriceFlights: {},
      flightsWithSamePriceDialog: false
    }
  },
  methods: {
    getThisDate (date) {
      const [year, month, day] = date.split('-')
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      return `${day} ${months[month - 1]} ${year}`
    },
    expand (id) {
      if (id === this.currentFlight) {
        this.show = !this.show
      } else {
        this.show = true
        this.currentFlight = id
      }
    },
    openSamePriceFlightsDialog (flights, id) {
      this.samePriceFlights = {
        flights: flights,
        relatedFlightId: id
      }
      this.flightsWithSamePriceDialog = true
    },
    book (id, relatedFlightId) {
      if (!relatedFlightId) relatedFlightId = 'parent'
      this.$router.push({ name: 'book', params: { id: id, search: this.$store.state.flightResults.search_id, relatedFlightId: relatedFlightId } })
    //   window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style>
    .layover {
        background-color: #fff;
        position: absolute;
        padding: 3px 10px;
        left: 50%;
        transform: translate(-50%, -15px);
    }
    .v-data-table
    /* /deep/ */
    tbody
    /* /deep/ */
    tr:hover:not(.v-data-table__expanded__content) {
        padding-top: 5px;
        background: white !important;
    }
    .airline {
        font-size: 15px;
    }
    .date {
        z-index: 1;
        background-color: white;
        position: relative;
    }
    .segments {
        position: relative;
    }
    h5 {
        margin-top: 10px;
    }
    .select {
        position: relative;
        border-left: 0.1px solid #E0E0E0;
    }
    /*
    .price {
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
    } */
    .price {
        border-left: 0.5px solid #e0e0e0;
    }
    .actions {
        border-top: 0.1px solid #E0E0E0;
        background-color: #F3F3F3;
    }
    .stops {
        transform: rotate(90deg) translate(-10px, -50%);
    }
    .table {
        width: 100%;
    }
    .list-enter, .list-leave-to {
        opacity: 0;
    }
    .list-enter-active, .list-leave-active {
        transition: opacity 0.5s ease;
    }
</style>
